import React from 'react'
import "./../WhiteDiv/WhiteDiv.css"
function WhiteDiv() {
  return (
    <div className='main_div_whitediv'>
      
    </div>
  )
}

export default WhiteDiv
