import React from "react";
import "./../AboutUsNavbar/AboutUsNavbar.css";
function AboutUsNavbar() {
  return (
    <div className="main_div_contactusheader">
    <p className="headercontactus_para">ABOUT US</p>
  </div>
  );
}

export default AboutUsNavbar;
