import React from "react";
import "./../WhyusNavbar/WhyusNavbar.css";
function WhyusNavbar() {
  return (
    <div className="main_div_contactusheader">
    <p className="headercontactus_para">WHY US</p>
  </div>
  );
}

export default WhyusNavbar;
